@import "../../scss";

.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &.pending {
    pointer-events: none;
  }
}

.container {
  width: 300px;
}

@import "../../scss";

.ocr {
  max-width: 500px;
  margin: 0 auto;
}

.creditDataBox {
  padding: 30px;
  border-radius: 8px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  .title {
    font-size: 1.5rem;
    font-weight: 400;
    color: $x-nord0;
  }
  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $nord4;
      height: 50px;
      .key {
        color: $x-nord1;
        font-size: 1.125rem;
        font-weight: 400;
      }
      .value {
        color: $nord3;
        font-size: 1.125rem;
        font-weight: 400;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.inputBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 18px;
  border-radius: 8px;
  background: white;
  margin-bottom: 20px;
  .textInput {
    .textInputTitle {
      line-height: 20px;
      color: lighten($x-nord1, 15%);
      margin-bottom: 10px;
    }
  }
  .fileInput {
    cursor: pointer;
    border: 1px dotted $nord4;
    border-radius: 8px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    .bold {
      font-weight: 600;
    }
    .fileInputTitle {
      line-height: 20px;
      color: lighten($x-nord1, 15%);
    }
    .fileInputDescription {
      color: lighten($x-nord1, 15%);
      font-weight: 400;
    }
    input {
      display: none;
    }
    &:hover {
      border-color: $x-nord1;
      color: $x-nord1;
    }
    &.dragActive {
      border-color: $x-nord1;
      color: $x-nord1;
    }
  }
  .footerBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .buttonGroup {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    .info {
      font-size: 0.875rem;
      color: $x-nord1;
      span {
        font-weight: 600;
        font-size: 0.875rem;
      }
    }
  }
}

.result {
  height: min-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .resultBox {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
    padding: 40px 14px;
    background: white;
    .resultBox_title {
      font-size: 1.125rem;
      color: $x-nord1;
      font-weight: 400;
    }
    .resultBox_icon_success {
      color: $nord14;
    }
    .resultBox_icon_error {
      color: $nord11;
    }
    .resultBox_resultText {
      color: $nord2;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }
  .links {
    display: flex;
    gap: 20px;
  }
}

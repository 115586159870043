@import "../../scss";

.loginPan {
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.inputs {
	display: flex;
	flex-direction: column;
	gap: 14px;
}

.submit {
  cursor: pointer;
  position: relative;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: $nord6;
  background: darken($nord10, 7%);
  .submitText {
    z-index: 10;
    position: relative;
    transition: 80ms ease-in-out;
    transform: translateY(-3px);
    font-weight: 400;
		color: $nord6;
  }
  &:hover {
    &::after {
      background: lighten($nord10, 5%);
    }
  }
  &:active {
    .submitText {
      transform: translateY(0);
    }
    &::after {
      top: 0;
    }
  }
  &::after {
    content: "";
    left: 0;
    top: -3px;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    transition: 80ms ease-in-out;
    background: $nord10;
  }
}
